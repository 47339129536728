import React from 'react';

import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import TwitterIcon from '@mui/icons-material/Twitter';
import ChatIcon from '@mui/icons-material/Chat';

import { Settings } from './Settings';
import imageLogo from '../images/logo.png';

const HeaderRoot = styled('div')({
  padding: 8,
  borderRadius: 4,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const SocialLinks = styled('div')({});

const Logo = styled('img')({
  marginTop: 50,
  marginBottom: 50,
  width: 250,
  height: 250,
});

const LogoContainer = styled('div')({
  flex: '1 1 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default function Header() {
  return (
    <HeaderRoot>


      <LogoContainer>
        <Logo src={imageLogo} />
      </LogoContainer>


    </HeaderRoot>
  );
}
